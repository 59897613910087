import React from 'react';
import "../css/alerta.css";

const AlertaComponent = ({ advertencia, mostrarPorcentajes, tresPrimeros, onCerrar, onContinuar, mostrarBotonContinuar, mensajeTresPrimeros, icon }) => {
    return (
        <div className="container alerta-fondo">
            <div className="alerta-popup col-md-4">
                <div className = "icon">{icon}</div>
                <p>{advertencia}</p>
                {mostrarPorcentajes && (
                    <div>
                        <h3>{mensajeTresPrimeros}</h3>
                        <ul className='resultados'>
                            {tresPrimeros.map(([signo, porcentaje]) => (
                                <li key={signo}>
                                    <b> {signo}:</b> {porcentaje.toFixed(2)}%
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="alerta-botones row">
                    <button onClick={onCerrar} className='col-md-4 btn button_closed'>Cerrar</button>
                    {mostrarBotonContinuar && (
                        <button onClick={onContinuar} className='col-md-4 btn button_continue'>Continuar</button>
                    )}
                </div>
           
            </div>
        </div>

    );
};

export default AlertaComponent;