import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SelectComponent from './SelectComponent';
import "../css/alertaDiscover.css";

const AlertDiscoverSign = ({ onCerrar }) => {
    const [day, setDays] = useState('');
    const [month, setMonths] = useState('');
    const [mostrarResultado, setMostrarResultado] = useState(false);
    const [resultado, setResultado] = useState('');
    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const mesesNumeros = {
        'Enero': 1,
        'Febrero': 2,
        'Marzo': 3,
        'Abril': 4,
        'Mayo': 5,
        'Junio': 6,
        'Julio': 7,
        'Agosto': 8,
        'Septiembre': 9,
        'Octubre': 10,
        'Noviembre': 11,
        'Diciembre': 12
    };

    const handleSeleccionDays = (e) => {
        const newSelectedOption = e.target.value;
        setDays(newSelectedOption);
    };

    const handleSeleccionMonths = (e) => {
        const newSelectedOption = e.target.value;
        setMonths(newSelectedOption);
    };

    const calcularSignoZodiacal = () => {
        var result = '';
        const fechasLimites = {
            'Aries': { inicio: { mes: mesesNumeros['Marzo'], dia: 21 }, fin: { mes: mesesNumeros['Abril'], dia: 19 } },
            'Tauro': { inicio: { mes: mesesNumeros['Abril'], dia: 20 }, fin: { mes: mesesNumeros['Mayo'], dia: 20 } },
            'Géminis': { inicio: { mes: mesesNumeros['Mayo'], dia: 21 }, fin: { mes: mesesNumeros['Junio'], dia: 20 } },
            'Cáncer': { inicio: { mes: mesesNumeros['Junio'], dia: 21 }, fin: { mes: mesesNumeros['Julio'], dia: 22 } },
            'Leo': { inicio: { mes: mesesNumeros['Julio'], dia: 23 }, fin: { mes: mesesNumeros['Agosto'], dia: 22 } },
            'Virgo': { inicio: { mes: mesesNumeros['Agosto'], dia: 23 }, fin: { mes: mesesNumeros['Septiembre'], dia: 22 } },
            'Libra': { inicio: { mes: mesesNumeros['Septiembre'], dia: 23 }, fin: { mes: mesesNumeros['Octubre'], dia: 22 } },
            'Escorpio': { inicio: { mes: mesesNumeros['Octubre'], dia: 23 }, fin: { mes: mesesNumeros['Noviembre'], dia: 21 } },
            'Sagitario': { inicio: { mes: mesesNumeros['Noviembre'], dia: 22 }, fin: { mes: mesesNumeros['Diciembre'], dia: 21 } },
            'Capricornio': { inicio: { mes: mesesNumeros['Diciembre'], dia: 22 }, fin: { mes: mesesNumeros['Enero'], dia: 19 } },
            'Acuario': { inicio: { mes: mesesNumeros['Enero'], dia: 20 }, fin: { mes: mesesNumeros['Febrero'], dia: 18 } },
            'Piscis': { inicio: { mes: mesesNumeros['Febrero'], dia: 19 }, fin: { mes: mesesNumeros['Marzo'], dia: 20 } }
        };

        for (const signo in fechasLimites) {
            const inicio = fechasLimites[signo].inicio;
            const fin = fechasLimites[signo].fin;

            if ((mesesNumeros[month] === inicio.mes && day >= inicio.dia) || (mesesNumeros[month] === fin.mes && day <= fin.dia)) {
                setMostrarResultado(true);
                setResultado(signo);
                result = signo;
                break;
            }
        }

        if (result === '') {
            setMostrarResultado(true);
            setResultado('Fecha no válida');
        }
    };


    return (
        <div className="container alerta-fondo-discover">
            <div className="alerta-popup-discover col-md-4">
                <p>Para saber cuál es tu signo zodiacal, selecciona tu día y mes de nacimiento.</p>
                <diV className='col-md-12'>
                    <SelectComponent
                        data={days}
                        seleccion={day}
                        handleSeleccionChange={handleSeleccionDays}
                        label="Dia de Nacimiento"
                        className="col-md-12" />
                </diV>
                <diV className='col-md-12' >
                    <SelectComponent
                        data={months}
                        seleccion={month}
                        handleSeleccionChange={handleSeleccionMonths}
                        label="Mes de Nacimiento"
                        className="col-md-12" />
                </diV>
                {mostrarResultado && (
                    <div className='resultadoSigno'>
                        <p>Tu signo es:</p>
                        <div><Link className='resultadoSigno-link' to={`/signo/${resultado.toLowerCase()}`}>{resultado}</Link></div>
                    </div>
                )}

                <div className="alerta-botones-discover row">
                    <button onClick={calcularSignoZodiacal} className='col-md-4 button_search'>Buscar</button>
                </div>
                <div className="alerta-botones-discover row">
                    <button onClick={onCerrar} className='col-md-4 button_closed'>Cerrar</button>
                </div>

            </div>
        </div>

    );
};

export default AlertDiscoverSign;