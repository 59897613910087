import React from 'react';
import { Link } from 'react-router-dom';
import NavBarComponent from './NavBarComponent'
import FooterComponent from './FooterComponent';
import ScrollToTopButton from './ScrollToTopButton';
import LoadDataComponent from './LoadDataComponent';
import LoadingIndicator from './LoadingIndicator';
import { API_URL } from './ConstantsComponent';
import '../css/aries.css';

const SignoInfoComponent = (item) => {
    const { data, path } = item;
    const [signosData, error, loading] =  LoadDataComponent(API_URL + '/signo/' + path);
    const msgError = "- No se encontro la informacion solicitada -";

    return (
        <div className='allPage'>
            <NavBarComponent />
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div className="container">
                    <div className="col-12 contenido-error" >
                        <div className="row">
                            <div className="col-md-12">
                                <img src="/sol_luna/sol.png" alt="Imagen de perfil" className="img-fluid" />
                            </div>
                        </div>
                        <h4>{msgError}</h4>
                    </div>
                </div>
            ) : (
                <div className="container body_signo" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className='header-info-signo'>
                                <img src={data.image} alt="Imagen de perfil" className="img-fluid" />
                                <h2>{data.title}</h2>
                                <Link className='saber-signo-ascendente' to="/ascendente">
                                    ¿Quieres saber mas sobre tu signo <u>ascendente</u>?</Link>
                                <div className='emotions_a'>
                                    <a href="#Amor">Amor</a>
                                    <a href="#Salud">Salud</a>
                                    <a href="#Dinero">Dinero</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 contenido-temas" >
                            {signosData.map((item, index) => (
                                <div id={item.topic} className="mt-4" key={index}>

                                    <h3><img src={item.image} alt="Imagen de perfil" className="d-inline-block align-top" width="30"
                                        height="30" />
                                        {item.answer}
                                    </h3>

                                    {item.paragraphs.map((paragraph, index) => (
                                        <div key={index}>
                                            <p><b className='espaciado'>{paragraph.title}</b>{paragraph.paragraph}</p>
                                        </div>
                                    ))}

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <ScrollToTopButton />
            <FooterComponent />
        </div>
    );
};

export default SignoInfoComponent;