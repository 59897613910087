import React from "react";
import NavBarComponent from "./NavBarComponent";
import FooterComponent from "./FooterComponent";
import ScrollToTopButton from "./ScrollToTopButton";
import "../css/privacy.css"

const PrivacyPolicyComponent = () => {
    return (
        <div className="allPage">
            <NavBarComponent />
            <div className="container content">
                <div className="row">
                    <div className="col-md-12 ">
                        <h1>Políticas de Privacidad</h1>

                        <p><strong>YourSoulWhisper</strong> se compromete a proteger la privacidad de sus usuarios. Esta Política de Privacidad explica cómo recopilamos, utilizamos y compartimos la información que usted nos proporciona cuando utiliza nuestro sitio web, aplicación móvil o cualquier otro servicio que ofrecemos (en conjunto, los "Servicios").</p>

                        <h2>Información que recopilamos:</h2>
                        <ul>
                            <li>Información de uso: Recopilamos información sobre cómo utiliza nuestros Servicios, como las páginas que visita, las funciones que utiliza y la frecuencia con la que utiliza nuestros Servicios.</li>
                            <li>Información del dispositivo: Recopilamos información sobre el dispositivo que utiliza para acceder a nuestros Servicios, como su dirección IP, tipo de dispositivo y sistema operativo.</li>
                            <li>Información de cookies: Recopilamos información a través de cookies y otras tecnologías de seguimiento. Las cookies son pequeños archivos de datos que se almacenan en su dispositivo y que nos permiten rastrear su comportamiento en línea.</li>
                            {/* <li>Información de Google Adsense: Recopilamos información sobre cómo interactúa con los anuncios de Google Adsense que se muestran en nuestros Servicios.</li> */}
                        </ul>

                        <h2>Cómo utilizamos la información:</h2>
                        <ul>
                            <li>Proporcionarle los Servicios: Utilizamos la información que recopilamos para proporcionarle los Servicios que solicita, como generar horóscopos y ofrecerle contenido personalizado.</li>
                            <li>Mejorar nuestros Servicios: Utilizamos la información que recopilamos para mejorar nuestros Servicios, como desarrollar nuevas funciones y personalizar su experiencia.</li>
                            {/* <li>Comunicarnos con usted: Utilizamos la información que recopilamos para comunicarnos con usted sobre nuestros Servicios, como para enviarle correos electrónicos con actualizaciones o información sobre nuevas funciones.</li> */}
                            <li>Investigación y análisis: Utilizamos la información que recopilamos para realizar investigaciones y análisis sobre cómo se utilizan nuestros Servicios.</li>
                            {/* <li>Mostrar publicidad: Utilizamos la información que recopilamos para mostrarle anuncios relevantes de Google Adsense.</li> */}
                        </ul>

                        <h2>Cómo compartimos la información:</h2>
                        <ul>
                            <li>No vendemos su información personal: No vendemos su información personal a terceros.</li>
                            <li>Proveedores de servicios: Compartimos su información con proveedores de servicios que nos ayudan a proporcionar los Servicios, como empresas de alojamiento web y proveedores de análisis de datos.</li>
                            {/* <li>Google Adsense: Compartimos información con Google Adsense para que puedan mostrarle anuncios relevantes.</li> */}
                            {/* <li>Requisitos legales: Podemos compartir su información cuando lo exija la ley o cuando creamos de buena fe que es necesario para proteger nuestros derechos o los derechos de otros.</li> */}
                        </ul>

                        <p className="vigencia"><strong>Fecha de vigencia: </strong>2024-03-01</p>
                    </div>
                </div>
            </div>
            <ScrollToTopButton />
            <FooterComponent />
        </div>
    );
};

export default PrivacyPolicyComponent