import React from 'react';
import ScrollToTopButton from './ScrollToTopButton';
import LoadDataComponent from './LoadDataComponent';
import LoadingIndicator from './LoadingIndicator';
import { API_URL } from './ConstantsComponent';
import '../css/aries.css';

const SignoAscendenteInfo = (item) => {
    const { tuSigno, tuAscendente } = item;
    const signo = tuSigno.charAt(0).toLowerCase() + tuSigno.slice(1);
    const ascendente = tuAscendente.charAt(0).toLowerCase() + tuAscendente.slice(1);

    const path = signo + "_" + ascendente;
    const [signosData, error, loading] = LoadDataComponent(API_URL + '/ascendente/' + path);

    return (
        <div className='allPage'>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div className="container">
                    <div className="col-12 contenido-error" >
                        <div className="row">
                            <div className="col-md-12">
                                <img src="/sol_luna/sol.png" alt="Imagen de perfil" className="img-fluid" />
                            </div>
                        </div>
                        <h4>- Selecciona un signo y un ascendente valido -</h4>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="container body_signo" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className='imagenes-signo'>
                                    <img src="/sol_luna/sol.png" alt="Imagen de perfil" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 contenido-ascendente" >
                                {(signosData.map((item, index) => (
                                    <div id={item.topic} className="mt-4" key={index}>
                                        <h3>
                                            {item.answer}
                                        </h3>
                                        {item.paragraphs.map((paragraph, index) => (
                                            <div key={index}>
                                                <p><b className='espaciado'>{paragraph.title}</b>{paragraph.paragraph}</p>
                                            </div>
                                        ))}

                                    </div>
                                )))}
                            </div>
                        </div>
                    </div>
                    <ScrollToTopButton />
                </React.Fragment>
            )}
        </div>
    );
};


export default SignoAscendenteInfo;