import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import FooterComponent from "./FooterComponent";
import NavBarComponent from "./NavBarComponent";
import "../css/articulos_cards.css"
import SubNavBarComponent from "./SubNavBarComponent";
import ScrollToTopButton from "./ScrollToTopButton";
import data from "../json/cards.json"


const ArticulosComponent = () => {
    const rows = Math.ceil(data.length / 3);
    const rowArray = Array.from({ length: rows });

    return (

        <div>
            <NavBarComponent></NavBarComponent>
            <SubNavBarComponent paragraphString={"Articulos"} />
            <Container className="articulos-container">
                {rowArray.map((_, rowIndex) => (
                    <Row key={rowIndex}>
                        {data.slice(rowIndex * 3, (rowIndex + 1) * 3).map((item) => (

                            <Col md={4} key={item.title}>
                                <div id="header_cards_articulos">
                                    <div id="header_cards_superpuesto_articulos">
                                        <Link to={item.link}>
                                            <img src={item.img} alt="imagen del articulo" loading="lazy" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="cards_body">
                                    <div className="col-md-12 cards-intro-signs">
                                        <h2 className="h2-responsive text-center">{item.title}</h2>
                                        <p className="text-sm text-md">{item.paragraph}</p>
                                    </div>
                                </div>
                                <div className="container link_body">
                                    <Link to={item.link}>Saber mas
                                    </Link>
                                </div>
                                <div className='col-md-12 cards_base'></div>
                            </Col>
                        ))}
                    </Row>
                ))}
            </Container>
            <ScrollToTopButton />
            <FooterComponent></FooterComponent>
        </div>

    );
}

export default ArticulosComponent;