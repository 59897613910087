import React from "react";
import FooterComponent from "./FooterComponent";
import NavBarComponent from "./NavBarComponent";
import LoadDataComponent from "./LoadDataComponent";
import LoadingIndicator from "./LoadingIndicator";
import { API_URL } from './ConstantsComponent';
import "../css/articulos_cards.css"
import { loadDivImage } from "./ParagraphUtilsComponent"
import { loadDivImageParagraph } from "./ParagraphUtilsComponent"
import ScrollToTopButton from "./ScrollToTopButton";

const ArticulosComponent = (item) => {
    const { path } = item;
    const [cardsData, error, loading] = LoadDataComponent(API_URL + '/article/' + path);
    const msgError = "- No se encontro la informacion solicitada -";

    return (
        <React.Fragment>
            <div className="allPage">
                <NavBarComponent />
                {loading ? (
                    <LoadingIndicator />
                ) : error ? (
                    <div className="container">
                        <div className="col-12 contenido-error" >
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="/sol_luna/sol.png" alt="Imagen del articulo" className="img-fluid" />
                                </div>
                            </div>
                            <h4>{msgError}</h4>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                {cardsData.map((item, index) => (
                                    <div className="cards_body_info">
                                        <img src={item.img} alt="card imagen" className="imagenes-signo_img" ></img>
                                        <h3 key={index}>{item.title}</h3>
                                        <div>
                                            {item.paragraphs.map((paragraph, index) => (
                                                <div className="row">
                                                    {loadDivImage(paragraph)}
                                                    {loadDivImageParagraph(paragraph)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <ScrollToTopButton />
                <FooterComponent />
            </div>
        </React.Fragment>
    );
}

export default ArticulosComponent;