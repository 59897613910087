import React, { useState } from "react";
import SignoAscendenteInfo from "./SignoAscendenteInfo";
import "../css/twoselectoptions.css";
import SelectComponent from "./SelectComponent";

function SignoZodiaco({ signo, ascendente }) {
    return (
        <div>
            {signo && ascendente && <SignoAscendenteInfo tuSigno={signo} tuAscendente={ascendente} />}
        </div>
    );
};

const SabesTuSignoAscendenteComponent = () => {
    const [seleccion1, setSeleccion1] = useState('');
    const [seleccion2, setSeleccion2] = useState('');
    const [showComponent, setShowComponent] = useState(false);

    const signos = ['Aries', 'Tauro', 'Geminis', 'Cancer', 'Leo', 'Virgo',
        'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'];

    const handleSeleccion1Change = (e) => {
        const newSelectedOption = e.target.value;
        setSeleccion1(newSelectedOption);
        setShowComponent(false);
    };

    const handleSeleccion2Change = (e) => {
        const newSelectedOption = e.target.value;
        setSeleccion2(newSelectedOption);
        setShowComponent(false);
    };

    const handleClickReload = () => {
        setShowComponent(true);
    };

    return (
        <div className="container mt-4 div-border">
            <div className="row">
                <SelectComponent
                    data={signos}
                    seleccion={seleccion1}
                    handleSeleccionChange={handleSeleccion1Change}
                    label="Selecciona tu signo"/>

                <SelectComponent
                    data={signos}
                    seleccion={seleccion2}
                    handleSeleccionChange={handleSeleccion2Change}
                    label="Selecciona tu signo ascendente"/>
            </div>
            <div className="mt-4">
                <div className="info-signo-seleccion">
                    <p>Eres del signo <b className="tu-signo-texto">{seleccion1}</b> con ascendente en <b className="signo-ascendente-texto">{seleccion2}</b></p>

                </div>

                <div className="btn-group w-100" role="group">
                    <button className="btn-saber-mas" onClick={handleClickReload}>
                        Click, para saber más
                    </button>
                </div>

            </div>
            {showComponent && <SignoZodiaco signo={seleccion1} ascendente={seleccion2} />}
        </div>
    );
}


export default SabesTuSignoAscendenteComponent;