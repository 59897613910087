import React from "react";
import '../css/subnavbar_bar.css';

const SubNavBarComponent = ({ paragraphString }) => {

  const paragraphs = paragraphString.split(',');
  return (
    <React.Fragment>
      <div className="sub_body_bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            {paragraphs.map((paragraph, index) => (
                <h2 key={index}>{paragraph}</h2>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubNavBarComponent;