import React, { Component } from "react";

class ScrollToTopButton extends Component {
  constructor() {
    super();
    this.state = {
      isShown: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 200) {
      this.setState({ isShown: true });
    } else {
      this.setState({ isShown: false });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    const buttonStyle = {
        display: this.state.isShown ? "block" : "none",
        // position: "relative",
        marginBottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#4acaa8",
        color: "#fff",
        fontWeight: "bold",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
        zIndex: "-1024px"
      };

    return (
      <button
        id="btnVolverArriba"
        onClick={this.scrollToTop}
       style={buttonStyle}
      >
        Volver Arriba
      </button>
    );
  }
}

export default ScrollToTopButton;
