import React, { useState, useEffect } from "react";
import "../css/twoselectoptions.css";
import SignoAscendenteInfo from "./SignoAscendenteInfo";
import horarios from "../json/horas.json"
import SelectComponent from "./SelectComponent";


function SignoZodiaco({ signo, hora }) {
    const [ascendente, setAscendente] = useState("");

    useEffect(() => {
        const [inicio, fin] = hora.split(" - ");
        horarios.forEach((item, index) => {
            if (item.signo === signo) {
                item.horas.forEach((hora, horaIndex) => {
                    if (hora.from === inicio && hora.until === fin) {
                        setAscendente(hora.ascendente);
                    }
                });
            }
        });
    }, [signo, hora]);

    return (
        <div>
            {signo && ascendente && (
                <SignoAscendenteInfo tuSigno={signo} tuAscendente={ascendente} />
            )}
        </div>
    );
};


const AscendenteByHoursComponent = () => {
    const [seleccion1, setSeleccion1] = useState('');
    const [seleccion2, setSeleccion2] = useState('');
    const [showComponent, setShowComponent] = useState(false);

    const signos = ['Aries', 'Tauro', 'Geminis', 'Cancer', 'Leo', 'Virgo',
        'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'];

    const hours = ['00:00 - 02:00', '02:00 - 04:00', '04:00 - 06:00', '06:00 - 08:00', '08:00 - 10:00', '10:00 - 12:00', '12:00 - 14:00', '14:00 - 16:00', '16:00 - 18:00', '18:00 - 20:00', '20:00 - 22:00', '22:00 - 00:00'];


    const handleSeleccion1Change = (e) => {
        const newSelectedOption = e.target.value;
        setSeleccion1(newSelectedOption);
        setShowComponent(false);
    };

    const handleSeleccion2Change = (e) => {
        const newSelectedOption = e.target.value;
        setSeleccion2(newSelectedOption);
        setShowComponent(false);
    };

    const handleClickReload = () => {
        setShowComponent(true);
    };

    return (
        <div className="container mt-4 div-border">
            <div className="row">
                 <SelectComponent
                    data={signos}
                    seleccion={seleccion1}
                    handleSeleccionChange={handleSeleccion1Change}
                    label="Selecciona tu signo" />
                <SelectComponent
                    data={hours}
                    seleccion={seleccion2}
                    handleSeleccionChange={handleSeleccion2Change}
                    label="Selecciona el rango de tu hora de nacimiento" />
            </div>
            <div className="mt-4">
                <div className="info-signo-seleccion">
                    <p>Eres del signo <b className="tu-signo-texto">{seleccion1}</b> nacido entre estas horas:  <b className="signo-ascendente-texto">{seleccion2}</b></p>

                </div>

                <div className="btn-group w-100" role="group">
                    <button className="btn-saber-mas" onClick={handleClickReload}>
                        Click, para saber más
                    </button>
                </div>


            </div>
            {showComponent && <SignoZodiaco signo={seleccion1} hora={seleccion2} />}
        </div>
    );
}


export default AscendenteByHoursComponent;