import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/navbar.css';


const NavBarComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="justify-content-between">
      <Container className="containerNav">
        <Navbar.Brand as={Link} to="/" className="title navbar-brand">
          <img
            src="/navbar/icons_magic.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Logo"
          />
          SoulWhisper
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav navbar">
          <Nav className="ml-auto">
            <Nav.Link className="navbar_link" as={Link} to="/compatibilidad"> Compatibilidad </Nav.Link>
            <Nav.Link className="navbar_link" as={Link} to="/ascendente"> Ascendente </Nav.Link>
            <Nav.Link className="navbar_link" as={Link} to="/horoscopo"> Horoscopo </Nav.Link>
            <Nav.Link className="navbar_link" as={Link} to="/articulos"> Articulos </Nav.Link>
            <Nav.Link className="navbar_link" as={Link} to="/20Q"> 20Q </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;