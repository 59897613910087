import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMoneyBill, faMedkit } from '@fortawesome/free-solid-svg-icons';
import data from "../json/signos_div_data.json"
import SelectComponent from "./SelectComponent";

import '../main.css';
import '../css/amor_salud_dinero_colummns.css';
import '../css/cards.css';
import '../css/emotions_icons.css';

const SignosComponent = () => {
  const [seleccion1, setSeleccion1] = useState('');

  const rows = Math.ceil(data.length / 3);
  const rowArray = Array.from({ length: rows });
  const signos = ['Aries', 'Tauro', 'Geminis', 'Cancer', 'Leo', 'Virgo',
    'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'];


  const handleSeleccion1Change = (e) => {
    const newSelectedOption = e.target.value;
    const element = document.getElementById(newSelectedOption);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
      setSeleccion1(newSelectedOption);
    }
  };



  return (

    <Container className="articles-container" id="articles">
      <div>
        <SelectComponent
          data={signos}
          seleccion={seleccion1}
          handleSeleccionChange={handleSeleccion1Change}
          label="Selecciona tu signo del Zodiaco"
          className="col-md-12" />
      </div>

      {rowArray.map((_, rowIndex) => (
        <Row key={rowIndex}>
          {data.slice(rowIndex * 3, (rowIndex + 1) * 3).map((sign) => (
            <Col md={4} key={sign.title} id={sign.title}>
              <div className="header_cards" >
                <div className="header_cards_superpuesto">
                  <img src={sign.image} loading="lazy" alt="Imagen de perfil" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="cards-body">
                  <p className='infoSigns-title'>{sign.title}</p>
                  <p className='p-date'>{sign.date}</p>

                  <div className='cards_emotions_icons'>
                    <FontAwesomeIcon icon={faHeart} className="custom-icon" id="Amor" />
                    <FontAwesomeIcon icon={faMedkit} className="custom-icon" id="Salud" />
                    <FontAwesomeIcon icon={faMoneyBill} className="custom-icon" id="Dinero" />
                  </div>
                  <p className='cards-intro-signs'>{sign.intro}</p>

                  <div className="container">
                    <Link className='cards-link-saber_mas' to={`/signo/${sign.title.toLowerCase()}`}>
                      Saber mas</Link>
                  </div>

                </div>
              </div>
              <div className='col-md-12 cards_base'></div>
            </Col>
          ))}
        </Row>
      ))}
    </Container>

  );
};

export default SignosComponent;
