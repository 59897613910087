import React from "react";

function SelectComponent({data, seleccion, handleSeleccionChange, label, className }) {

  if(className === undefined ){
    className = "col-md-6";
  }

    return (
      <div className={className}>
        <select className="form-select" value={seleccion} onChange={handleSeleccionChange}>
          <option value="">{label}</option>
          {data.map((opcion, index) => (
            <option key={index} value={opcion}>
              {opcion}
            </option>
          ))}
        </select>
      </div>
    );
  }

  export default SelectComponent;