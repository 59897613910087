import React, { useState } from 'react';
import NavBarComponent from "./NavBarComponent";
import FooterComponent from "./FooterComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import data from "../json/compatibilidad.json"
import "../css/compatibilidad.css"
import ScrollToTopButton from './ScrollToTopButton';
import SubNavBarComponent from './SubNavBarComponent';


const CompatibilidadComponent = () => {
    const [infoVisible, setInfoVisible] = useState(null);
    const [seleccion1, setSeleccion1] = useState('');

    const handleSeleccion1Change = (e) => {
        const newSelectedOption = e.target.value;
        setSeleccion1(newSelectedOption);
        mostrarInfo(newSelectedOption);
    };

    const mostrarInfo = (index) => {
        const signoAries = data[index];
        if (signoAries) {
            const compatibles = signoAries.compatibles.map((compatible, subIndex) => (
                <div className = "compatibleFeel" key={subIndex}>
                    <h3>{compatible.feel}</h3>
                    <p>{compatible.descripcion}</p>
                </div>
            ));

            setInfoVisible(
                <div>
                    <h3>{signoAries.boton}</h3>
                    <p>{signoAries.descripcion}</p>
                    <div className='compatibilidad-icons'>
                        <FontAwesomeIcon icon={faFire} className="compatibilidad-icon" id="Fire" />
                    </div>
                    <h3>Signos compatibles:</h3>
                    <p className='head_compatible_paragraph'><b>{signoAries.signosCompatibles}</b></p>
                    {compatibles}
                </div>
            );
        } else {
            console.error(`No se encontró información para el botón en el índice ${index}`);
        }
    };

    return (
        <div className="allPage">
            <NavBarComponent />
            <SubNavBarComponent paragraphString={"Compatibilidad"}/>
            <div>
                <div className="container col-md-12 compatibilidad_body">
                    <p>La <b>"compatibilidad entre signos"</b> es un concepto mágico que nos revela la afinidad cósmica entre dos personas basada en la posición de los astros en el momento de su nacimiento. Cada signo zodiacal lleva consigo una energía única y una vibración celestial que puede influir en la dinámica de las relaciones humanas.</p>
                    <p>En la danza cósmica de la astrología, cuando dos almas comparten el mismo elemento (tierra, agua, fuego o aire), sienten una conexión profunda, como si estuvieran sintonizadas con la misma frecuencia estelar. Esto les permite fluir en armonía y comprensión mutua.</p>
                    <p>Asimismo, los signos con cualidades complementarias (cardinal, fijo o mutable) pueden encontrar equilibrio en sus diferencias, creando una relación dinámica y en constante evolución.</p>
                    <div className="row">
                        <div className="col-md-12 ">
                            <select className="form-select" value={seleccion1} onChange={handleSeleccion1Change}>
                                <option value="">Selecciona tu signo del Zodiaco</option>
                                {data.map((opcion, index) => (
                                    <option key={index} value={opcion.index}>
                                        {opcion.boton}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="container col-md-12">
                    {infoVisible && <div className='info-container'>{infoVisible}</div>}
                </div>
            </div>
            <ScrollToTopButton />
            <FooterComponent />
        </div>
    );
}

export default CompatibilidadComponent;