import React from "react";
import '../css/footer.css';

const FooterComponent = () => {
    return (
        <div className="footer_bg">
            <div className="container footer_bg">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-icon">
                            <a href="https://twitter.com/YourSoulWhisper" target="_blank" rel="noopener noreferrer">
                                <img src="/redes_sociales/xTwitter.png" alt="xTwitter"/>
                            </a>
                            <a href="https://instagram.com/yoursoulwhisper/" target="_blank" rel="noopener noreferrer">
                                <img src="/redes_sociales/instagram.png" alt="instagram"/>
                            </a>
                        </div>
                        <p><a href="/privacy_policy">Política de privacidad</a></p>
                        <p>©2023 DreamSoul - All rights reserved | Designed By: Soul </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterComponent