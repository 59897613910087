import React, { useState, useEffect } from "react";
import '../css/banner.css';
import config from '../json/gadsense.json';

const BannerComponent = () => {

  // const [mostrarAnuncio, setMostrarAnuncio] = useState(false);
  // useEffect(() => {

  //   setMostrarAnuncio(config.mostrarAnuncio);
  //   if (config.mostrarAnuncio) {
  //     const script = document.createElement('script');
  //     script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5824706514377467";
  //     script.async = true;
  //     script.crossOrigin = "anonymous";
  //     document.head.appendChild(script);
  //     script.onload = () => {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     };
  //   }

  // }, []);

  return (
    <React.Fragment>
      <div className="banner_bg">
        <div className="banner-bg-image">
        <h2><span>Bienvenido</span></h2>
          <h3><span>A tu pequeño rincon astrologico.</span></h3>
          </div>
      </div>
      {/* {mostrarAnuncio && (
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-5824706514377467"
          data-ad-slot="3089697845"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins>
      )} */}
    </React.Fragment>
  );
};

export default BannerComponent;