import React from 'react';
import NavBarComponent from "./components/NavBarComponent";
import BannerComponent from './components/BannerComponent';
import SignosComponent from './components/SignosComponent';
import FooterComponent from './components/FooterComponent';
import InstagramBarComponent from './components/InstagramBarComponent';
import ScrollToTopButton from './components/ScrollToTopButton';
import ArticulosTopThreeComponent from './components/ArticulosTopThreeComponent';

function App() {
  return (
    <div>
      <NavBarComponent />
      <BannerComponent />
      <ArticulosTopThreeComponent />
      <InstagramBarComponent/>
      <SignosComponent />
      <ScrollToTopButton />
      <FooterComponent />
    </div>
  );
}

export default App;

