import React, { useState } from 'react';
import '../css/instagram_bar.css';
import AlertDiscoverSign from './AlertDiscoverSign';

const InstagramBarComponent = () => {
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  const loadAlert = () => {
    setMostrarAlerta(true);
  };

  const cerrarAlerta = () => {
    setMostrarAlerta(false);
  };

  return (
    <React.Fragment>
      <div className="instagram_body_bg">
        <div className="container">
          <div className="row">
            <diV className='col-sm-12 miSignoTitle '><p>¿Cual es tu signo?</p></diV>
            <div className='col-md-12 botonesBar' >
              <button onClick={loadAlert} className='cards-link-saber_mas_discover'>Descubrelo</button>
            </div>
          </div>
        </div>
        {mostrarAlerta && (
          <AlertDiscoverSign onCerrar={cerrarAlerta} />
        )}
      </div>
    </React.Fragment >
  );
};

export default InstagramBarComponent;