import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LoadDataComponent from "./LoadDataComponent";
import LoadingIndicator from "./LoadingIndicator";
import FooterComponent from "./FooterComponent";
import NavBarComponent from "./NavBarComponent";
import SubNavBarComponent from './SubNavBarComponent';
import ScrollToTopButton from "./ScrollToTopButton";

import '../main.css';
import '../css/amor_salud_dinero_colummns.css';
import '../css/horoscopo_cards.css';
import '../css/emotions_icons.css';
import SelectComponent from './SelectComponent';

const HoroscopoComponent = () => {
    const [data, error, loading] = LoadDataComponent('/horoscopo_semanal/horoscopo.json');
    const [seleccion1, setSeleccion1] = useState('');

    if (!data || !data.signos) {
        return null;
    }

    const signos = ['Aries', 'Tauro', 'Geminis', 'Cancer', 'Leo', 'Virgo',
    'Libra', 'Escorpio', 'Sagitario', 'Capricornio', 'Acuario', 'Piscis'];

    const rows = Math.ceil(data.signos.length / 3);
    const msgError = "- No se encontro la informacion solicitada -";
    const title = data.title;
    const subTitle = data.fecha;

    const handleSeleccion1Change = (e) => {
        const newSelectedOption = e.target.value;
        const element = document.getElementById(newSelectedOption);
        if (element) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
          });
          setSeleccion1(newSelectedOption);
        }
    }

    return (
        <div>
            <NavBarComponent></NavBarComponent>
            <SubNavBarComponent paragraphString={title + "," + subTitle}></SubNavBarComponent>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div className="container">
                    <div className="col-12 contenido-error" >
                        <div className="row">
                            <div className="col-md-12">
                                <img src="/sol_luna/sol.png" alt="Imagen de perfil" className="img-fluid" />
                            </div>
                        </div>
                        <h4>{msgError}</h4>
                    </div>
                </div>
            ) : (

                <Container className="horoscopo_container" id="articles">
                    <div>
                        <SelectComponent
                            data={signos}
                            seleccion={seleccion1}
                            handleSeleccionChange={handleSeleccion1Change}
                            label="Selecciona tu signo del Zodiaco"
                            className="col-md-12" />
                    </div>
                    {[...Array(rows)].map((_, rowIndex) => (
                        <Row key={rowIndex}>
                            {data.signos.slice(rowIndex * 3, (rowIndex + 1) * 3).map((sign) => (
                                <Col md={4} key={sign.title} id={sign.title}>
                                    <div className='horoscopo_cards_contenedor'>
                                        <div id='horoscopo_header_cards' >
                                            <div id="horoscopo_header_cards_superpuesto">
                                                <img src={sign.image} loading="lazy" alt="Imagen de perfil" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 horoscopo-cards-body">
                                            <div >
                                                <p className='infoSigns-title'>{sign.title}</p>
                                                <p className='p-date'>{sign.date}</p>
                                                <p className='horoscopo-cards-intro-signs '>{sign.intro}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-12 horoscopo_cards_base'></div>
                                    </div>

                                </Col>
                            ))}
                        </Row>
                    ))}
                </Container>
            )}
            <ScrollToTopButton></ScrollToTopButton>
            <FooterComponent></FooterComponent>

        </div>

    );
}

export default HoroscopoComponent;
