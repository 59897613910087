import React, { useState } from "react";
import NavBarComponent from "./NavBarComponent";
import FooterComponent from "./FooterComponent";
import SabesTuSignoAscendenteComponent from "./SabesTuSignoAscendenteComponent";
import '../css/ascendente.css'
import AscendenteByHoursComponent from "./AscendenteByHoursComponent";
import SubNavBarComponent from "./SubNavBarComponent";

function DescubrirTuSignoAscendenteComponent() {
    return <SabesTuSignoAscendenteComponent />
};

function DescubrirAscendenteFormComponent() {
    return <AscendenteByHoursComponent />
};

const SignoAscendenteComponent = () => {
    const [mostrarComponente, setMostrarComponente] = useState(false);


    const handleClick = (componente) => {
        setMostrarComponente(componente);
    };

    return (
        <div className="allPage">
            <NavBarComponent />
            <SubNavBarComponent paragraphString={"Ascendente"}/>

            <div className="container col-md-12 ascendente_body">
                <p>Los signos ascendentes, también conocidos como el "ascendente" o "signo del ascendente", son una parte esencial de la astrología y juegan un papel fundamental en la forma en que experimentas el mundo y te relacionas con los demás. Tu ascendente es el signo que estaba ascendiendo en el horizonte oriental en el momento de tu nacimiento y actúa como una máscara o fachada inicial que presentas al mundo.</p>
                <p>Los signos ascendentes son una herramienta invaluable para el autoconocimiento y el crecimiento personal. Al comprender mejor tu ascendente, podrás desvelar aspectos ocultos de tu ser y tomar decisiones más informadas en todos los aspectos de tu vida, desde tus relaciones hasta tu carrera y tu desarrollo espiritual.</p>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="btn-group w-100" role="group">
                            <button
                                type="button"
                                className="btn_sabes_tu_signo"
                                onClick={() => handleClick(<DescubrirTuSignoAscendenteComponent />)}>
                                ¿Sabes tu signo Ascendente?
                            </button>
                            <button
                                type="button"
                                className="btn_cual_es_tu_signo"
                                onClick={() => handleClick(<DescubrirAscendenteFormComponent />)}>
                                ¿Quieres saber tu signo Ascendente?
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-4">{mostrarComponente}</div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default SignoAscendenteComponent;