import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faFaceSadTear } from '@fortawesome/free-solid-svg-icons';
import NavBarComponent from './NavBarComponent';
import SubNavBarComponent from './SubNavBarComponent';
import AlertaComponent from './AlertaComponent';
import preguntasJson from '../json/questions.json';
import ScrollToTopButton from './ScrollToTopButton';
import FooterComponent from './FooterComponent';
import discoverTable from '../json/discoverTable.json'
import '../css/discover_sign.css';

const DiscoverSignComponent = () => {
    const [respuestas, setRespuestas] = useState({});
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tresPrimeros, setTresPrimeros] = useState([]);
    const [mostrarBotonContinuar, setMostrarBotonContinuar] = useState(true);
    const [iconItem, setIconItem] = useState({});
    const [advertencia, setAdvertencia] = useState("");
    const preguntas = preguntasJson.map(({ index, pregunta }) => ({ index, pregunta }));
    const tablaRespuestas = discoverTable;
    const [mostrarMsgComplete, setMostrarMsgComplete] = useState(false);

    const [preguntaActual, setPreguntaActual] = useState(0); // Estado para controlar la pregunta actual

    const avanzarPregunta = () => {
        if (preguntaActual >= preguntas.length - 1) {
            setMostrarMsgComplete(true);
        }
        setPreguntaActual((prevPregunta) => prevPregunta + 1);
    };

    const handleRespuesta = (index, respuesta) => {
        const nuevasRespuestas = { ...respuestas };
        nuevasRespuestas[index] = respuesta;
        setRespuestas(nuevasRespuestas);
        avanzarPregunta();
    };

    const calcularPorcentajeAciertos = (respuestasCliente) => {
        const porcentajes = {};
        for (const [signo, respuestasSigno] of Object.entries(tablaRespuestas)) {
            let respuestasCorrectas = 0;
            let totalPreguntas = 0;
            for (let i = 0; i < preguntas.length; i++) {
                const respuestaCorrecta = respuestasSigno[i];
                if (respuestasCliente.hasOwnProperty(i)) {
                    totalPreguntas++;
                    const respuestaCliente = respuestasCliente[i];
                    if (respuestaCorrecta === (respuestaCliente === true ? 1 : 0)) {
                        respuestasCorrectas++;
                    }
                }
            }
            const porcentaje = (respuestasCorrectas / totalPreguntas) * 100;
            if (porcentaje >= 20) {
                porcentajes[signo] = porcentaje;
            }
        }

        const signosOrdenados = Object.entries(porcentajes).sort((a, b) => b[1] - a[1]);
        const tresPrimeros = signosOrdenados.slice(0, 6);
        return tresPrimeros;
    };


    const enviarRespuestas = () => {
        const preguntasRespondidas = Object.keys(respuestas).length;
        if (preguntasRespondidas >= 1) {
            const tresPrimerosCalculados = calcularPorcentajeAciertos(respuestas);
            if (tresPrimerosCalculados.length > 0) {
                setAdvertencia("");
                setTresPrimeros(tresPrimerosCalculados);
                setMostrarBotonContinuar(false);
                setMostrarAlertaMethod(true);
            }
        } else {
            setMostrarBotonContinuar(true);
            setMostrarAlertaMethod(true);
        }
    };

    const reiniciarRespuestas = () => {
        setMostrarBotonContinuar(true);
        setRespuestas([]);
        setTresPrimeros([]);
        setPreguntaActual(0);
        setMostrarMsgComplete(false);
    };

    const cerrarAlerta = () => {
        setMostrarAlerta(false);
    };

    const continuarSinResponder = () => {

        if (Object.keys(respuestas).length < 1) {
            setMostrarAlertaMethod(true, true);
        }

        if (Object.keys(respuestas).length >= 1) {
            const tresPrimerosCalculados = calcularPorcentajeAciertos(respuestas);
            if (tresPrimerosCalculados.length > 0) {
                setAdvertencia("");
                setTresPrimeros(tresPrimerosCalculados);
                setMostrarBotonContinuar(false);
                setMostrarAlertaMethod(true);
            } else {
                setMostrarAlertaMethod(false);
            }
        }


    };

    const setMostrarAlertaMethod = (flag, botonContinuar) => {
        definirValores(botonContinuar);
        setMostrarAlerta(flag);
    }


    const definirMensajeTresPrimeros = () => {
        var isNan = false;
        for (let i = 0; i < tresPrimeros.length; i++) {

            if (isNaN(tresPrimeros[i][1])) {
                isNan = true;
                break;
            }
        }

        if (isNan) {
            return "";
        } else {
            return "Los signos con mas aciertos segun tus respuestas: ";
        }

    }

    const definirValores = (botonContinuar) => {
        setIconItem("");
        if (Object.keys(respuestas).length === 0 && botonContinuar) {
            setIconItem(<FontAwesomeIcon icon={faFaceSadTear} className='sad' />);
            setMostrarBotonContinuar(false);
            setAdvertencia(<p>Deberias intentar contestar al menos una pregunta.</p>);
        }

        if (Object.keys(respuestas).length === 0 && !botonContinuar) {
            setIconItem(<FontAwesomeIcon icon={faTriangleExclamation} className='exclamation' />);
            setAdvertencia(<p>Si no contestas todas las preguntas, se calculara
                tu <b>signo</b> de forma incorrecta, debido a que se completaran las faltantes con un <b>No</b> como respuesta.</p>);
        }
    }

    return (
        <div>
            <NavBarComponent />
            <SubNavBarComponent paragraphString={"20Q"} />
            <Container className="discover">
                <h2>¡Bienvenido a nuestro Cuestionario Zodiacal!</h2>
                <p>Este cuestionario te ayudará a descubrir con cuales signos del zodiaco coincides. Responde sinceramente a las siguientes preguntas y al final podrás ver cuántos aciertos obtuviste. ¡Comencemos!</p>
                {mostrarMsgComplete ? (
                    <div className='msgCompletado'>
                        <h2>¡Genial!</h2>
                        <p> Has respondido todas las preguntas. Ahora puedes ver tus resultados al presionar el botón <b>Procesar Respuestas</b> o,
                            si prefieres comenzar de nuevo, puedes presionar <b>Reiniciar</b>.</p>
                    </div>
                ) : (
                    preguntasJson.map((item, index) => (
                        <div key={item.index} className='col-md-6 contenedor_cards' style={{ display: item.index === preguntaActual ? 'block' : 'none' }} >
                            <div id="header_cards_questions">
                                <div id="header_cards_superpuesto_questions">
                                    <div id="header_cards_superpuesto_questions_number">
                                        <h1 className='header_cards_superpuesto_questions_number_h1'>{item.index + 1}</h1>
                                    </div>


                                </div>
                            </div>
                            <div className="cards-discover">
                                <p className='col-md-12'>{item.pregunta}</p>
                                <div className="row botones">
                                    <div className="col-md-6">
                                        <button
                                            className={respuestas[item.index] === true ? "btn_si activo" : "btn_si"}
                                            onClick={() => handleRespuesta(item.index, true)}
                                        >
                                            Sí
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            className={respuestas[item.index] === false ? "btn_no activo" : "btn_no"}
                                            onClick={() => handleRespuesta(item.index, false)}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 cards_base_questions'></div>
                        </div>
                    ))
                )}
                <button onClick={enviarRespuestas} className='show_answers'>Procesar Respuestas</button>
                <button onClick={reiniciarRespuestas} className='reset_answers'>Reiniciar</button>
                {mostrarAlerta && (
                    <AlertaComponent
                        mostrarPorcentajes={tresPrimeros.length > 0}
                        tresPrimeros={tresPrimeros}
                        onCerrar={cerrarAlerta}
                        onContinuar={continuarSinResponder}
                        advertencia={advertencia}
                        mostrarBotonContinuar={mostrarBotonContinuar}
                        mensajeTresPrimeros={definirMensajeTresPrimeros()}
                        icon={iconItem}
                    />
                )}

            </Container >
            <ScrollToTopButton />
            <FooterComponent />
        </div >
    );
};

export default DiscoverSignComponent;
