import React from 'react';

const LoadingIndicator = () => {
  return (
    <div className="loading_style">
      <img src="/miscellaneous/loading.gif" alt="Loading indicator" />
    </div>
  );
};

export default LoadingIndicator;