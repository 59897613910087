import React from 'react';
import './css/principal.css';
import App from './App';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignoInfoComponent from './components/SignoInfoComponent';
import { useParams } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import SignoAscendenteComponent from './components/SignoAscendenteComponent';
import CompatibilidadComponent from './components/CompatibilidadComponent';
import ArticulosComponent from './components/ArticulosComponent';
import ArticulosInfoComponent from './components/ArticulosInfoComponent';
import HoroscopoComponent from './components/HoroscopoComponent';
import PrivacyPolicyComponent from './components/PrivacyPolicyComponent';
import DiscoverSignComponent from './components/DiscoverSignComponent';

function SignoZodiaco() {
  const { title } = useParams();
  const signo = title.charAt(0).toUpperCase() + title.slice(1);
  let signoData = {
    title: signo,
    image: "/signos/" + title + ".png",
  };
  return <SignoInfoComponent data={signoData} path={title} />
};


function RendesArticulosInfo() {
  const { title } = useParams();
  const file_name = title.charAt(0).toLowerCase() + title.slice(1);
  return <ArticulosInfoComponent path={file_name} />
};


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/compatibilidad" element={<CompatibilidadComponent />} />
      <Route path="/ascendente" element={<SignoAscendenteComponent />} />
      <Route path="/signo/:title" element={<SignoZodiaco />} />
      <Route path="/articulos" element={<ArticulosComponent />} />
      <Route path="/articulos/:title" element={<RendesArticulosInfo />} />
      <Route path="/horoscopo" element={<HoroscopoComponent />} />
      <Route path="/privacy_policy" element={< PrivacyPolicyComponent />} />
      <Route path="/20Q" element={< DiscoverSignComponent />} />
    </Routes>
  </Router>
);
