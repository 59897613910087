import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "../css/articulos_cards.css"
import SubNavBarComponent from "./SubNavBarComponent";
import data from "../json/cards.json"


const ArticulosTopThreeComponent = () => {
    const topThreeData = data.slice(0, 3);
    return (
        <div>
            <SubNavBarComponent paragraphString={"Ultimos articulos"} />
            <Container className="articulos-container">
                <Row>
                    {topThreeData.map((item) => (

                        <Col md={4} key={item.title}>

                            <div class="card">
                                <div class="label">{item.label}</div>
                                <Link to={item.link}> <img src={item.img_index} alt="Imagen tarjeta" class="card-img" /></Link>
                                <div class="card-text">
                                    <h2 className="h2-responsive text-center h2-size">{item.title}</h2>
                                    <div className="container link_body">
                                        <Link to={item.link}>Saber mas
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>

    );
}

export default ArticulosTopThreeComponent;