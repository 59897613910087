import React from "react";

const verifyImportantParagraph = (paragraph) => {

    let subTitle = "";
    if (paragraph.subtitle) {
        subTitle = paragraph.subtitle + ": ";
    }

    if (paragraph.important) {
        const partes = paragraph.paragraph.split(paragraph.important);
        return <p>
            <strong>{subTitle}</strong>
            {partes[0]}<strong>{paragraph.important}</strong>{partes[1]}</p>
    }
    return <p>
        <strong>{subTitle}</strong>
        {paragraph.paragraph}</p>
}

export const loadDivImage = (paragraph) => {
    if (paragraph.img) {
        return <div className="col-sm-1">
            <img src={paragraph.img} alt="card imagen" className="d-inline-block"></img>
        </div>
    } else {
        return <div className=""></div>
    }
}

export const loadDivImageParagraph = (paragraph) => {
    if (paragraph.img) {
        return <div className="col-sm-11">
            {verifyImportantParagraph(paragraph)}
        </div>

    } else {
        return <div className="col-sm-12">
            {verifyImportantParagraph(paragraph)}
        </div>
    }
}


