import { useEffect, useState, useMemo } from 'react';

const LoadDataComponent = (path) => {
  const fetchData = useMemo(
    () => async (postData) => {
      try {
        const requestOptions = {
          method: postData ? 'POST' : 'GET', // Determina el método según si se envió postData
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        };

        if (postData) {
          requestOptions.body = JSON.stringify(postData);
        }

        const response = await fetch(path, requestOptions);

        if (!response.ok) {
          throw new Error('El servidor responde con un error!');
        }

        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [path]
  );

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const datosCaché = localStorage.getItem('datos_caché');
    if (datosCaché) {
      const datosCodificados = JSON.stringify(datosCaché);
      const datosUTF8 = new TextEncoder().encode(datosCodificados);
      setData(datosUTF8);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [fetchData]);

  return [data, error, loading];
};

export default LoadDataComponent;